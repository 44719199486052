<template>
    <div>
        <global-page-back
            :detailPageTitle="secondLevelPageTitle"
            @handlerGlobalType="handlerGlobalType">
            <div slot="globalPageBackTabs">
                <div class="other-content">
                    <div class="wrap">
                        <div @click="changeActive('基础信息')">
                            <span :class="activeItem == '基础信息' ? 'active' : ''">1、基础信息</span>
                            <img v-if="activeItem == '基础信息'" :src="selectedIcon" alt="">
                        </div>
                        <div @click="changeActive('方案设计')">
                            <span :class="activeItem == '方案设计' ? 'active' : ''">2、方案设计</span>
                            <img v-if="activeItem == '方案设计'" :src="selectedIcon" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </global-page-back>
        <div class="content-wrap" id="content">
            <div class="content">
                <div v-show="activeItem == '基础信息'" class="basic-information">
                    <el-form
                        class="common-form"
                        ref="formRef"
                        :model="rulesForm"
                        :rules="rules"
                        label-width="91px"
                    >
                    <div class="title">
                        <div class="text">基础设置</div>
                        <div class="line"></div>
                    </div>
                    <el-form-item label="方案名称" prop="name">
                        <el-input
                            v-model="rulesForm.name"
                            placeholder="请输入"
                            maxlength="20"
                            show-word-limit
                            class="input-width"
                            :disabled="rulesForm.status == '2'"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="巡课教师" prop="patrolTeacherId">
                        <div class="custom-input"  @click="clickTeacher">
                            <span v-if="rulesForm.patrolTeacherName == ''" style="color: #C0C4CC">请选择</span>
                            <div v-else class="person">
                                <span v-for="(item, index) in rulesForm.patrolTeacherName.split(',')" :key="index" ref="patrolTeacher_refs">{{ index < rulesForm.patrolTeacherName.split(',').length - 1 ? item + ',' : item }}</span>
                            </div>
                            <span v-if="patrolTeacher_nums" class="nums"> {{ '+' + patrolTeacher_nums }}</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="巡课方式" prop="type">
                        <el-radio-group v-model="rulesForm.type" :disabled="rulesForm.status == '2'" @input="changeCourseType">
                            <el-radio label="1">自由巡课</el-radio>
                            <el-radio label="2">固定课程巡课</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="巡课对象" prop="tourObject">
                        <div v-if="rulesForm.type=='1'" class="custom-input"  :class="rulesForm.status == '2' ? 'disabled-input' : ''" @click="clickInput">
                            <span v-if="rulesForm.tourObject == ''" style="color: #C0C4CC">请选择</span>
                            <div v-else class="person">
                                <span v-for="(item, index) in rulesForm.tourObject.split(',')" :key="index" ref="tourObject_refs">{{ index < rulesForm.tourObject.split(',').length - 1 ? item + ',' : item }}</span>
                            </div>
                            <span v-if="tourObject_nums" class="nums"> {{ '+' + tourObject_nums }}</span>
                        </div>
                        <el-button v-else-if="rulesForm.type=='2'" v-model="rulesForm.tourObject"  :disabled="rulesForm.status == '2'" class="fixed-object" @click="clickInput">
                            <span v-if="!rulesForm.tourObject" class="tip">请选择</span>
                            <div v-if="rulesForm.tourObject" class="fixed-before">
                                <div class="fixed-info">{{rulesForm.tourObject}}</div>
                                <img :src="closeImg" alt="" class="fixed-img" @click.stop="delFixedPatrol">
                            </div>
                            <div v-if="tourObjectLength>0" class="fixed-num">+{{tourObjectLength}}</div>
                        </el-button>
                    </el-form-item>
                    <el-form-item v-if="rulesForm.type == '1'" label="选择日期" prop="time">
                        <el-date-picker
                            v-model="rulesForm.time"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 360px"
                            @change="dateChange">
                        </el-date-picker>
                    </el-form-item>
                    <div class="Parting-line"></div>
                    <div class="title">
                        <div class="text">评价类型</div>
                        <div class="line"></div>
                    </div>
                    <el-form-item label="评价类型" prop="assessType">
                        <el-checkbox-group v-model="rulesForm.assessType" :disabled="rulesForm.status == '2'">
                        <el-checkbox label="1">教师行为</el-checkbox>
                        <el-checkbox label="2">学生行为</el-checkbox>
                        <el-checkbox label="3">备注说明</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <div class="Parting-line"></div>
                    <div class="title">
                        <div class="text">消息推送</div>
                        <div class="line"></div>
                    </div>
                        <el-form-item label="推送类型" prop="pushType">
                        <el-checkbox-group v-model="rulesForm.pushType" class="type" :disabled="rulesForm.status == '2'" @change="pushTypeChange">
                            <el-checkbox label="1">任教班级、负责班级、班级权限教师</el-checkbox>
                            <el-checkbox label="2">上课教师</el-checkbox>
                            <el-checkbox label="3">按职务推送</el-checkbox>
                            <el-select
                                v-if="rulesForm.pushType.includes('3')"
                                v-model="rulesForm.postScopeId"
                                placeholder="请选择"
                                multiple
                                collapse-tags
                                :disabled="rulesForm.status == '2'"
                                style="width: 360px"
                                @change="postScopeChange"
                            >
                            <el-option
                                v-for="item in optionsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                            </el-select>
                            <el-checkbox label="4">自定义推送人</el-checkbox>
                        </el-checkbox-group>
                        <select-list4
                            v-if="rulesForm.pushType.includes('4')"
                            ref="selectList"
                            :key="timestamp"
                            :data="treeFrom4"
                            :tree-data="treeData"
                            :rules-form="rulesForm"
                            :type="'customScope'"
                            style="width: 100%;"
                            @saveSelectList="saveSelectList"
                        />
                        </el-form-item>
                    </el-form>
                <dialog-wrapper
                    :dialogObj="teacherDialog"
                    @handleClose="handleTeacherDialogClose"
                >
                    <select-list
                        ref="selectList"
                        :key="timestamp"
                        :data="treeFrom"
                        :tree-data="treeData"
                        :rules-form="rulesForm"
                        :type="'patrolTeacher'"
                        style="width: 100%;"
                        @saveSelectList="saveSelectList"
                        @handleTeacherDialogClose="handleTeacherDialogClose"
                    />
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="selTreeDialog"
                    @handleClose="handleTreeDialogClose"
                >
                    <div class="range-wrap">
                        <div class="btn-group">
                            <span :class="rulesForm.targetType == '1' ? 'active' : ''" @click="typeChange('1')">按班级巡</span>
                            <span :class="rulesForm.targetType == '2' ? 'active' : ''" @click="typeChange('2')">按教师巡</span>
                        </div>
                        <select-list2
                            v-if="rulesForm.targetType == '1'"
                            ref="selectList"
                            :key="timestamp"
                            :data="treeFrom2"
                            :tree-data="treeData2"
                            :rules-form="rulesForm"
                            :classData="classData"
                            :type="'classTarget'"
                            style="width: 100%;"
                            @saveSelectList="saveSelectList"
                            @handleTreeDialogClose="handleTreeDialogClose"
                            @setCheckedList="getCheckedList2"
                        />
                        <select-list3
                            v-if="rulesForm.targetType == '2'"
                            ref="selectList"
                            :key="timestamp"
                            :data="treeFrom3"
                            :tree-data="treeData"
                            :rules-form="rulesForm"
                            :type="'teacherTarget'"
                            style="width: 100%;"
                            @saveSelectList="saveSelectList"
                            @handleTreeDialogClose="handleTreeDialogClose"
                            @setCheckedList="getCheckedList3"
                        />
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    class="course-dialog"
                    :dialogObj="courseDialog"
                    @handleClose="handleTreeDialogClose"
                >
                    <course ref="childCourse" :key="timestamp2" :selectedCourseList="rulesForm.target[0] ? rulesForm.target[0].selectedCourseList : []" @handleTreeDialogClose="handleTreeDialogClose" @setTourObject="getTourObject"/>
                </dialog-wrapper>
                </div>
                <div v-show="activeItem == '方案设计'" class="scheme-design">
                    <!-- 左侧巡课内容列表 -->
                    <div class="left-content">
                        <div>
                            <div class="left-title">
                                <span>巡课内容</span>
                                <div class="line-bottom"></div>
                            </div>
                            <div class="left-tabs">
                                <el-radio-group v-model="groupType" @input="getGroupList">
                                    <el-radio-button label="">全部</el-radio-button>
                                    <el-radio-button label="1">教师</el-radio-button>
                                    <el-radio-button label="2">学生</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div class="left-plan">
                                <div v-for="item in groupList" :key="item.id">
                                    <div class="title" @click="fold(item)">
                                        <span>{{ item.groupName }}</span>
                                        <img v-if="item.checked" :src="upIcon" alt="">
                                        <img v-else :src="downIcon" alt="">
                                    </div>
                                    <div v-show="!item.checked">
                                        <div class="detail" v-for="i in item.list" :key="i.id" @click="getBehavior(i.id, i.groupType)">
                                            <el-tooltip class="item" effect="dark" :content="i.content" :disabled="i.content.length < 19" placement="top-start">
                                                <span>{{ i.content }}</span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="rulesForm.status != '2'" class="left-tips">
                            <div class="text">没有想要选择的指标？</div>
                            <div class="create" @click="jumpToContent">去创建</div>
                        </div>
                    </div>
                    <!-- 右侧内容区域 -->
                    <div class="right-content">
                        <div
                            v-if="!rulesForm.assessType.includes('1') && !rulesForm.assessType.includes('2') && !rulesForm.assessType.includes('3')"
                            style="height: calc(100% - 36px);display: flex;justify-content: center;align-items: center;"
                        >
                            <img :src="emptyImg" alt="">
                        </div>
                        <div v-else>
                            <div v-if="rulesForm.assessType.includes('1')" class="right-teacher" :class="teacherList.length == 0 ? '' : 'right-teacher2'">
                                <div class="right-title">
                                    <span class="title-line"></span>
                                    <span class="title-info">教师行为</span>
                                </div>
                                <div v-if="teacherList.length == 0" class="right-teacher-main">
                                    <div class="right-none">
                                        <img :src="emptyImg" alt="">
                                        <div class="right-none-info">请将左侧指标内容点击到该区域</div>
                                    </div>

                                </div>
                                <div v-else>
                                    <draggable v-model="teacherList" :options="dragOptions" :disabled="rulesForm.status == '2'">
                                        <div v-for="(item,index) in teacherList" :key="index" class="behavior-content">
                                            <div style="width: 96%">
                                                <div class="behavior-content-title">{{index+1}} 、 {{item.content}}</div>
                                                <div>
                                                    <div v-if="item.type == '1' || item.type == null" style="margin-left: 23px;margin-top: 10px; display: flex;flex-wrap: wrap;gap:10px;">
                                                        <el-radio-group v-for="(subItem,idx) in item.list" :key="idx" :readonly="true" >
                                                            <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.name,subItem.score)" :disabled="(subItem.name&&subItem.name.length>24) ? false : true">
                                                                <el-radio class="behavior-item" :label="subItem.id">
                                                                    <span>
                                                                        {{ subItem.name }} {{subItem.score != null ? '(' : ''}}{{ subItem.score > 0 ? `+` + subItem.score : subItem.score }}{{subItem.score != null ? '分' : ''}}{{subItem.score != null ? ')' : ''}}
                                                                    </span>
                                                                </el-radio>
                                                            </el-tooltip>
                                                        </el-radio-group>
                                                    </div>
                                                    <div v-else-if="item.type == '2'" style="margin-left: 23px;margin-top: 10px;display: flex;flex-wrap: wrap;gap:10px">
                                                        <el-checkbox-group v-for="(subItem,idx) in item.list" :key="idx" v-model="rulesForm2.teacher" :readonly="true" >
                                                            <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.name,subItem.score)" :disabled="(subItem.name&&subItem.name.length>24) ? false : true">
                                                                <el-checkbox class="behavior-item-checkbox" :readonly="true" :label="subItem.id">
                                                                    <span>
                                                                        {{ subItem.name }} {{subItem.score != null ? '(' : ''}}{{ subItem.score > 0 ? `+` + subItem.score : subItem.score }}{{subItem.score != null ? '分' : ''}}{{subItem.score != null ? ')' : ''}}
                                                                    </span>
                                                                </el-checkbox>
                                                            </el-tooltip>
                                                        </el-checkbox-group>
                                                    </div>
                                                    <div v-else-if="item.type == '3'" style="margin-left: 23px;margin-top: 10px">
                                                        <div v-if="item.list && item.list.length != 0 && item.list[0].lowScore != null" style="margin: 10px 0">
                                                            <el-input-number
                                                                :readonly="true"
                                                                :min="item.list[0].lowScore"
                                                                :max="item.list[0].highScore"
                                                                controls-position="right">
                                                            </el-input-number>
                                                            <span class="text"> ({{item.list[0].lowScore}}-{{item.list[0].highScore}}分)</span>
                                                        </div>
                                                        <el-input
                                                            type="textarea"
                                                            :rows="2"
                                                            placeholder="请填写"
                                                            maxlength="100"
                                                            show-word-limit
                                                            :readonly="true">
                                                        </el-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <img v-if="rulesForm.status != '2'" :src="delImg" alt="" @click="delBehavior(teacherList, index)">
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div v-if="rulesForm.assessType.includes('2')" class="behavior-line"></div>
                            <div v-if="rulesForm.assessType.includes('2')" class="right-student" :class="studentList.length == 0 ? '' : 'right-student2'">
                                <div class="right-title">
                                    <span class="title-line"></span>
                                    <span class="title-info">学生行为</span>
                                </div>
                                <div v-if="studentList.length == 0" class="right-student-main">
                                    <div class="right-none">
                                        <img :src="emptyImg" alt="">
                                        <div class="right-none-info">请将左侧指标内容点击到该区域</div>
                                    </div>
                                </div>
                                <div v-else>
                                    <draggable v-model="teacherList" :options="dragOptions" :disabled="rulesForm.status == '2'">
                                        <div v-for="(item,index) in studentList" :key="index" class="behavior-content">
                                            <div style="width: 96%">
                                                <div class="behavior-content-title">{{index+1}} 、 {{item.content}}</div>
                                                <div>
                                                    <div v-if="item.type == '1' || item.type == null" style="margin-left: 23px;margin-top: 10px;display: flex;flex-wrap: wrap;gap:10px">
                                                        <el-radio-group v-for="(subItem,idx) in item.list" :key="idx" :readonly="true">
                                                            <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.name,subItem.score)" :disabled="(subItem.name&&subItem.name.length>24) ? false : true">
                                                                <el-radio class="behavior-item" :label="subItem.id">{{ subItem.name }} {{subItem.score != null ? '(' : ''}}{{ subItem.score > 0 ? `+` + subItem.score : subItem.score }}{{subItem.score != null ? '分' : ''}}{{subItem.score != null ? ')' : ''}}</el-radio>
                                                            </el-tooltip>
                                                        </el-radio-group>
                                                    </div>
                                                    <div v-else-if="item.type == '2'" style="margin-left: 23px;margin-top: 10px;display: flex;flex-wrap: wrap;gap:10px">
                                                        <el-checkbox-group v-for="(subItem,idx) in item.list" :key="idx" v-model="rulesForm2.student" :readonly="true">
                                                            <el-tooltip class="item" effect="dark" :content="handleTooltip(subItem.name,subItem.score)" :disabled="(subItem.name&&subItem.name.length>24) ? false : true">
                                                                <el-checkbox class="behavior-item-checkbox" :label="subItem.id">{{ subItem.name }} {{subItem.score != null ? '(' : ''}}{{ subItem.score > 0 ? `+` + subItem.score : subItem.score }}{{subItem.score != null ? '分' : ''}}{{subItem.score != null ? ')' : ''}}</el-checkbox>
                                                            </el-tooltip>
                                                        </el-checkbox-group>
                                                    </div>
                                                    <div v-else-if="item.type == '3'" style="margin-left: 23px;margin-top: 10px">
                                                        <div v-if="item.list && item.list.length != 0 && item.list[0].lowScore != null" style="margin: 10px 0">
                                                            <el-input-number
                                                                :readonly="true"
                                                                :min="item.list[0].lowScore"
                                                                :max="item.list[0].highScore"
                                                                controls-position="right">
                                                            </el-input-number>
                                                            <span class="text"> ({{item.list[0].lowScore}}-{{item.list[0].highScore}}分)</span>
                                                        </div>
                                                        <el-input
                                                            type="textarea"
                                                            :rows="2"
                                                            placeholder="请填写"
                                                            maxlength="100"
                                                            show-word-limit
                                                            :readonly="true">
                                                        </el-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <img v-if="rulesForm.status != '2'" :src="delImg" alt="" @click="delBehavior(studentList, index)">
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div v-if="rulesForm.assessType.includes('3')" class="behavior-line"></div>
                            <div v-if="rulesForm.assessType.includes('3')" class="right-remark">
                                <div class="right-title">
                                    <span class="title-line"></span>
                                    <span class="title-info">备注说明</span>
                                </div>
                                <div class="right-remark-main">
                                    <el-input
                                        type="textarea"
                                        :rows="5"
                                        placeholder="请填写备注说明"
                                        maxlength="500"
                                        show-word-limit
                                        v-model="textarea">
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="botttom-btn">
            <xk-button v-if="activeItem == '方案设计'" type="primary" @click="changeActive('基础信息')"
                >上一步</xk-button
            >
            <xk-button v-if="activeItem == '基础信息'" type="primary" @click="changeActive('方案设计')"
                >下一步</xk-button
            >
            <xk-button type="info" v-if="rulesForm.status != '2'" @click="toSave('1')"
                >暂存</xk-button
            >
            <xk-button type="primary" @click="toSave('2')"
                >发布</xk-button
            >
        </div>
    </div>
</template>

<script>
import { DialogWrapper, debounce } from 'common-local'
import GlobalPageBack from '@/components/scrollWrapper/Sub/GlobalPageBack/index.vue';
import selectList from "./basicInformation/selectList";
import selectList2 from "./basicInformation/selectList2";
import selectList3 from "./basicInformation/selectList3";
import selectList4 from "./basicInformation/selectList4";
import { formatTreeData } from "@/libs/utils";
import course from "./basicInformation/course"
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { isEqual } from 'lodash'
export default {
    props: {
        keyArrTeacher: {
            type: Array
        },
        keyArrStu: {
            type: Array
        },
        editData: {
            type: Object
        },
    },
    components: {
        GlobalPageBack,
        DialogWrapper,
        selectList,
        selectList2,
        selectList3,
        selectList4,
        course,
        draggable
    },
    data() {
        return {
            secondLevelPageTitle: '添加巡课方案',
            activeItem: '基础信息',
            cloneRulesForm: {},
            rulesForm: {
              id: '',
              name: '',
              patrolTeacherId: '', // 后续需删除
              patrolTeacherName: '', // 后续需删除
              patrolTeacher: [],
              type: '1',
              tourObject: '', // 后续需删除
              classTargetId: '', // 后续需删除
              teacherTargetId: '', // 后续需删除
              target: [],
              targetType: '1',
              time: '', // 后续需删除
              startTime: '',
              endTime: '',
              assessType: ['1', '2', '3'], // 后续需转成字符串类型
              pushType: [], // 后续需删除
              isPermTeacher: '',
              isClassTeacher: '',
              isPost: '',
              postScope: [],
              postScopeId: [], // 后续需删除
              isCustom: '',
              customPushId: '', // 后续需删除
              customPushName: '', // 后续需删除
              customScope: [],
              status: '',
              list: [],
              deleteStatus: 0,
            },
            rulesForm2: {
                teacher: [],
                student: [],
                classId: '',
                assessType: [],
                pushType: [],
                postScope: []
            },
            rules: {
                name: [{required: true, message: "请输入方案名称", trigger: "blur"}],
                patrolTeacherId: [{required: true, message: "请选择巡课教师", trigger: "blur"}],
                type: [{required: true, message: "请选择巡课方式", trigger: "change"}],
                tourObject: [{required: true, message: "请选择巡课对象", trigger:"blur"}],
                time: [{required: true, message: "请选择巡课日期", trigger: "change"}],
                assessType: [{required: true, message: "请选择评价类型", trigger: "change"}],
                pushType: [{required: true, message: "请选择推送类型", trigger: "change"}],
            },
            treeFrom: {
                key: 'patrolTeacherId',
                value: '',
                list: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            },
            treeFrom2: {
                key: 'classTargetId',
                value: '',
                list: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            },
            treeFrom3: {
                key: 'teacherTargetId',
                value: '',
                list: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            },
            treeFrom4: {
                key: 'customPushId',
                value: '',
                list: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            },
            treeData: [],
            treeData2: [],
            treeData3: [],
            treeData4: [],
            classData: [],
            checkedList2: [],
            checkedList3: [],
            teacherDialog: {
                title: "选择巡课教师",
                dialogVisible: false,
                width: "550px",
                key: false,
            },
            selTreeDialog: {
                title: "选择巡课范围",
                dialogVisible: false,
                width: "550px",
                key: false,
            },
            courseDialog: {
                title: "选择巡课范围",
                dialogVisible: false,
                width: "80%",
                key: false,
            },
            timestamp: '',
            timestamp2: '',
            optionsList: [],
            groupType: '',
            groupList: [],
            textarea:'',
            teacherList: [],
            studentList: [],
            tourObjectLength:0,
            jumpFlag: true,
            dragOptions: {
                animation: 150, // 拖拽动画持续时间（毫秒）
            },
            patrolTeacher_nums: 0,
            tourObject_nums: 0
        };
    },
    computed: {
        selectedIcon() {
            return require('@/assets/images/process-sel.png')
        },
        downIcon() {
            return require('@/assets/images/down.png')
        },
        upIcon() {
            return require('@/assets/images/up.png')
        },
        emptyImg(){
            return require('@/assets/images/empty-patrol.png')
        },
        delImg(){
            return require('@/assets/images/behavior-del.png')
        },
        closeImg(){
            return require('@/assets/images/patrol-close.png')
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.pageMounted()
    },
    methods: {
        // 单选多选鼠标悬浮提示框有分值处理
        handleTooltip(name,score){
            if(score || score == 0){
                let a = ''
                if(score>0){
                    a = '+' + score + '分'
                }else{
                    a = score + '分'
                }
                return name + '(' + a + ')'
            }else{
                return name
            }
        },
        /**
         * @Description: 数据初始化
         * @Author: pyl
         * @Date: 2024-08-28 15:53:54
         */
        init() {
            this.getTreeData()
            this.getJobList()
            this.getGroupList()
        },
        /**
         * @Description: 页面加载
         * @Author: pyl
         * @Date: 2024-08-31 15:16:07
         */
        pageMounted() {
            this.cloneRulesForm = {}
            this.$nextTick(() => {
                document.getElementById('content').style.height = document.body.clientHeight - document.getElementById('content').offsetTop - 130 + 'px'
                document.getElementById('content').style.overflowY = 'auto'
                this.$refs.formRef.clearValidate(); // 清空校验
            })
            window.onresize = debounce(() => {
                document.getElementById('content').style.height = document.body.clientHeight - document.getElementById('content').offsetTop - 130 + 'px'
                document.getElementById('content').style.overflowY = 'auto'
            }, 200)
            if (this.editData != null) {
                this.secondLevelPageTitle = '编辑巡课方案'
                Object.keys(this.rulesForm).forEach(key => {
                    if (this.editData[key]) {
                        this.rulesForm[key] = this.editData[key]
                    } else {
                        if (typeof this.rulesForm[key] == 'string') {
                            this.rulesForm[key] = ''
                        } else if (typeof this.rulesForm[key] == 'number') {
                            this.rulesForm[key] = 0
                        } else {
                            this.rulesForm[key] = []
                        }
                    }
                });
                this.rulesForm.assessType = this.editData.assessType.split(',')
                this.rulesForm.pushType = []
                this.editData.isPermTeacher == '1' && this.rulesForm.pushType.push('1')
                this.editData.isClassTeacher == '1' && this.rulesForm.pushType.push('2')
                if (this.editData.isPost == '1') {
                    this.rulesForm.pushType.push('3')
                    this.rulesForm.postScopeId = []
                    this.editData.postScope.forEach(item => {
                        this.rulesForm.postScopeId.push(item.id)
                    })
                }
                if (this.editData.isCustom == '1') {
                    this.rulesForm.pushType.push('4')
                    this.editData.customScope.forEach((item, index) => {
                        const ids = index == this.editData.customScope.length - 1 ? item.ids : item.ids + ','
                        const name = index == this.editData.customScope.length - 1 ? item.name : item.name + ', '
                        this.rulesForm.customPushId += ids
                        this.rulesForm.customPushName += name
                    })
                }
                this.editData.patrolTeacher.forEach((item, index) => {
                    const ids = index == this.editData.patrolTeacher.length - 1 ? item.ids : item.ids + ','
                    const name = index == this.editData.patrolTeacher.length - 1 ? item.name : item.name + ', '
                    this.rulesForm.patrolTeacherId += ids
                    this.rulesForm.patrolTeacherName += name
                    this.getCustomInput('patrolTeacher_nums')
                })
                if (this.editData.type == '1') {
                    if (this.editData.startTime) {
                        this.rulesForm.time = [this.editData.startTime.split(' ')[0], this.editData.endTime.split(' ')[0]]
                    }
                    if (this.editData.targetType == '1') {
                        this.editData.target.forEach((item, index) => {
                            const id = index == this.editData.target.length - 1 ? item.id : item.id + ','
                            const name = index == this.editData.target.length - 1 ? item.name : item.name + ', '
                            this.rulesForm.classTargetId += id
                            this.rulesForm.tourObject += name
                            this.getCustomInput('tourObject_nums')
                        })
                    } else {
                        this.editData.target.forEach((item, index) => {
                            const ids = index == this.editData.target.length - 1 ? item.ids : item.ids + ','
                            const name = index == this.editData.target.length - 1 ? item.name : item.name + ', '
                            this.rulesForm.teacherTargetId += ids
                            this.rulesForm.tourObject += name
                            this.getCustomInput('tourObject_nums')
                        })
                    }
                } else {
                    this.editData.target.forEach((item, index) => {
                        const id = index == this.editData.target.length - 1 ? item.id : item.id + ','
                        const name = index == this.editData.target.length - 1 ? item.name : item.name + ', '
                        this.rulesForm.teacherTargetId += id
                        this.rulesForm.tourObject += name
                    })
                    this.tourObjectLength = this.editData.target.length - 1
                }
                if (this.keyArrTeacher) {
                    this.keyArrTeacher = this.keyArrTeacher.sort((a, b) => a.conSort - b.conSort)
                    this.teacherList = this.keyArrTeacher
                }
                if (this.keyArrStu) {
                    this.keyArrStu = this.keyArrStu.sort((a, b) => a.conSort - b.conSort)
                    this.studentList = this.keyArrStu
                }
            } else {
                this.secondLevelPageTitle = '添加巡课方案'
                Object.keys(this.rulesForm).forEach(key => {
                    if (typeof this.rulesForm[key] == 'string') {
                        this.rulesForm[key] = ''
                    } else {
                        this.rulesForm[key] = []
                    }
                })
                this.rulesForm.type = '1'
                this.rulesForm.targetType = '1'
                this.rulesForm.deleteStatus = 0
                this.rulesForm.assessType = ['1', '2', '3']
                delete this.rulesForm.id
                this.teacherList = []
                this.studentList = []
            }
            this.cloneRulesForm = this._.clone(this.rulesForm)
        },
        /**
         * @Description: 自定义输入框数据处理
         * @Author: pyl
         * @Date: 2024-09-12 09:53:20
         * @param {*} target
         * @param {*} nums
         */
        getCustomInput(type) {
            let count = 0;
            let width = 0;
            let nums = 0;
            let bool = true
            this.$nextTick(() => {
                let len = 0
                if (type == 'patrolTeacher_nums') {
                    len = this.rulesForm.patrolTeacherName.split(',').length
                    this.$refs.patrolTeacher_refs.map((item, index) => {
                        width += Number(item.offsetWidth);
                        if (width > 277 && bool) {
                            count = index;
                            bool = false;
                        }
                    })
                } else {
                    len = this.rulesForm.tourObject.split(',').length
                    this.$refs.tourObject_refs.map((item, index) => {
                        width += Number(item.offsetWidth);
                        if (width > 277 && bool) {
                            count = index;
                            bool = false;
                        }
                    })
                }
                if (count !== 0) {
                    nums = len - count
                } else {
                    nums = 0;
                }
                if (type == 'patrolTeacher_nums') {
                    this.patrolTeacher_nums = nums
                } else {
                    this.tourObject_nums = nums
                }
            })
        },
        /**
         * @Description: 获取按班级巡选择列表
         * @Author: pyl
         * @Date: 2024-09-08 21:56:13
         */
        getCheckedList2(list) {
            this.checkedList2 = list
        },
        /**
         * @Description: 获取按教师巡选择列表
         * @Author: pyl
         * @Date: 2024-09-08 21:56:13
         */
        getCheckedList3(list) {
            this.checkedList3 = list
        },
        /**
         * @Description: 如果是固定巡课，获取巡课对象
         * @Author: pyl
         * @Date: 2024-08-29 19:07:24
         */
        getTargetByProId(id) {
            this._fet('/school/schoolPatrolProgramme/getTargetByProId', {id}).then(res => {
                if(res.data.code == 200) {
                    console.log('巡课对象', res)
                    let tourObject = ''
                    if(res.data.data.length > 1){
                        tourObject =  res.data.data[0].teacherName + ' (' +  res.data.data[0].scheduleGradeName + '/' + res.data.data[0].className + ')' + ' ' + this.handleDate( res.data.data[0].scheduleTime)
                        this.rulesForm.tourObject = tourObject
                        this.tourObjectLength = res.data.data.length-1
                    }else{
                        this.rulesForm.tourObject = tourObject
                        this.tourObjectLength = 0
                    }
                } else if(res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 删除行为
         * @Author: pyl
         * @Date: 2024-08-31 17:41:03
         */
        delBehavior(arr, index) {
            arr.splice(index, 1)
        },
        /**
         * @Description: 返回
         * @Author: pyl
         * @Date: 2024-08-27 18:40:31
         */
        handlerGlobalType() {
            // 内容不变时直接返回
            if(isEqual(this.rulesForm, this.cloneRulesForm)){
                this.$emit('switchCom', {com: 'List'})
                return
            }
            const rulesForm = this._.clone(this.rulesForm)
            rulesForm.type = ''
            rulesForm.targetType = ''
            for (const prop in rulesForm) {
                if (rulesForm.hasOwnProperty(prop) && rulesForm[prop] !== null && rulesForm[prop] !== undefined && rulesForm[prop] !== '' && rulesForm[prop].length != 0) {
                    this.$confirm("提示", {
                        message: '已填写的内容是否保存？',
                        confirmButtonText: "是",
                        cancelButtonText: "否",
                        type: "warning",
                    })
                        .then(async () => {
                            await this.toSave(this.rulesForm.status || '1')
                        })
                        .catch(() => {
                            this.$emit('switchCom', {com: 'List'})
                        });
                    return
                }
            }
            this.$emit('switchCom', {com: 'List'})
        },
        /**
         * @Description: 巡课教师选择校验并关闭弹窗
         * @Author: pyl
         * @Date: 2024-08-26 15:52:26
         * @param {*} key
         */
        saveSelectList(key, value, type) {
            this.$refs.formRef.validateField(key);
            if (type == 'patrolTeacher') {
                this.handleTeacherDialogClose()
                this.rulesForm.patrolTeacher = []
                this.rulesForm.patrolTeacherName = value
                this.getCustomInput('patrolTeacher_nums')
                value.split(',').forEach((item, index) => {
                    this.rulesForm.patrolTeacher.push({
                        id: (this.rulesForm.patrolTeacherId.split(',')[index]).split('-')[0],
                        name: item,
                        ids: this.rulesForm.patrolTeacherId.split(',')[index]
                    })
                })
            } else if (type == 'classTarget') {
                this.handleTreeDialogClose()
                this.rulesForm.tourObject = value
                this.getCustomInput('tourObject_nums')
                this.rulesForm.target = []
                value.split(',').forEach((item, index) => {
                    this.rulesForm.target.push({
                        id: this.rulesForm.classTargetId.split(',')[index],
                        name: item
                    })
                })
                if (value) {
                    this.$refs.formRef.clearValidate('tourObject')
                } else {
                    this.$refs.formRef.validateField('tourObject')
                }
            } else if (type == 'teacherTarget') {
                this.handleTreeDialogClose()
                this.rulesForm.tourObject = value
                this.getCustomInput('tourObject_nums')
                this.rulesForm.target = []
                value.split(',').forEach((item, index) => {
                    this.rulesForm.target.push({
                        id: (this.rulesForm.teacherTargetId.split(',')[index]).split('-')[0],
                        name: item,
                        ids: this.rulesForm.teacherTargetId.split(',')[index]
                    })
                })
                if (value) {
                    this.$refs.formRef.clearValidate('tourObject')
                } else {
                    this.$refs.formRef.validateField('tourObject')
                }
            } else if (type == 'customScope') {
                this.rulesForm.customScope = []
                value.split(',').forEach((item, index) => {
                    this.rulesForm.customScope.push({
                        id: (this.rulesForm.customPushId.split(',')[index]).split('-')[0],
                        name: item,
                        ids: this.rulesForm.customPushId.split(',')[index]
                    })
                })
            }
            console.log('iweDSDJJDQW2104O32432R------------', this.rulesForm.patrolTeacher)
        },
        // 获取巡课教师数据
        async getTreeData() {
            this._fet('/school/schoolOrgan/getAllGradeClassList', {
                    schoolId: this.$store.state.schoolId,
                    jobScope: [3]
                }).then((res) => {
                    this.classData = res.data.data
                    this.treeData2 = formatTreeData(res.data.data, 'id', 'parentOrg')
                })
            this._fet('/school/schoolOrgan/getIssueScopeByAccess', {
                schoolId: this.$store.state.schoolId,
                applyPerson: '1'
            }).then((res) => {
                this.resetId(res.data.data)
                this.treeData = formatTreeData(res.data.data.filter(i => i.organType != 4), 'id', 'parentOrg')
            })
        },
        resetId(data, parentId) {
            data.forEach(item => {
                if (item.hasOwnProperty('parentOrg') && item.parentOrg == '') {
                    item.id = item.id
                } else {
                    item.id = item.id + '-' + parentId
                }
                if (item.children.length != 0) {
                    this.resetId(item.children, item.id)
                }
            })
        },
        /**
         * @Description: 关闭巡课教师弹窗
         * @Author: pyl
         * @Date: 2024-09-08 16:52:17
         */
        handleTeacherDialogClose() {
            this.teacherDialog.dialogVisible = false;
             setTimeout(() => {
                this.teacherDialog.key = false;
            }, 300);
        },
        /**
         * @Description: 关闭选择巡课对象弹窗
         * @Author: pyl
         * @Date: 2024-08-26 16:39:15
         */
        handleTreeDialogClose() {
            this.selTreeDialog.dialogVisible = false;
            this.courseDialog.dialogVisible = false;
            setTimeout(() => {
                this.selTreeDialog.key = false;
                this.courseDialog.key = false;
            }, 300);
        },
        /**
         * @Description: 打开选择巡课教师弹窗
         * @Author: pyl
         * @Date: 2024-09-08 16:56:05
         */
        clickTeacher() {
            this.timestamp = Date.now()
            this.teacherDialog.dialogVisible = true;
            this.teacherDialog.key = true;
        },
        /**
         * @Description: 打开选择巡课对象弹窗
         * @Author: pyl
         * @Date: 2024-08-26 16:44:10
         */
        clickInput() {
            if (this.rulesForm.status == '2') {
                return
            }
            if (this.rulesForm.type == '1') {
                this.timestamp = Date.now()
                this.selTreeDialog.dialogVisible = true;
                this.selTreeDialog.key = true;
            } else {
                this._fet('/school/schoolPatrolProgramme/hasCourse', {}).then((res) => {
                    if (res.data.data) {
                        this.courseDialog.dialogVisible = true;
                        this.courseDialog.key = true;
                    } else {
                        this.$message.warning('固定课程巡课，请先创建课表')
                    }
                })
                if (this.rulesForm.id) {
                    this.timestamp2 = Date.now()
                }
            }
        },
        /**
         * @Description: 获取自由巡课选择内容
         * @Author: pyl
         * @Date: 2024-08-29 16:31:08
         */
        getTourObject(obj) {
            this.rulesForm.target = []
            console.log('obj--',obj)
            this.handleTreeDialogClose()

            obj.selectList.forEach(item => {
                if(item.child.length>0){
                    item.child.forEach(c => {
                        this.rulesForm.target.push({
                            id: c.id,
                            name: c.teacherName + ' (' + c.className + ')' + ' ' + this.handleDate(c.scheduleTime)
                        })
                    })
                }
            })
            console.log('获取自由巡课选择内容', this.rulesForm.target)
            if(this.rulesForm.target.length>1){
                this.rulesForm.tourObject = obj.tourObject
                this.tourObjectLength = this.rulesForm.target.length-1
            }else{
                this.rulesForm.tourObject = obj.tourObject
                this.tourObjectLength = 0
            }
            if (obj.tourObject) {
                this.$refs.formRef.clearValidate('tourObject')
            } else {
                this.$refs.formRef.validateField('tourObject')
            }
            this.rulesForm.target[0].selectedCourseList = obj.selectList
        },
        /**
         * @Description:处理日期
         * @Author: mrz
         * @Date: 2024-08-26 10:25:53
         */
         handleDate(val){
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(val)
            return days[date.getDay()]  + ' (' + val + ')'

         },
        /**
         * @Description: 巡课类型切换
         * @Author: pyl
         * @Date: 2024-09-08 21:42:14
         */
        changeCourseType(value) {
            if (value == '2' && this.rulesForm.classTargetId != '') {
                this.$confirm("提示", {
                    message: '切换巡课方式，已填写的内容将清除，且不可恢复，是否继续？',
                    confirmButtonText: "继续",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.rulesForm.classTargetId = ''
                        this.rulesForm.tourObject = ''
                    })
                    .catch(() => {
                        this.rulesForm.type = '1'
                    })
            } else if (value == '2' && this.rulesForm.teacherTargetId != '') {
                this.$confirm("提示", {
                    message: '切换巡课方式，已填写的内容将清除，且不可恢复，是否继续？',
                    confirmButtonText: "继续",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.rulesForm.teacherTargetId = ''
                        this.rulesForm.tourObject = ''
                    })
                    .catch(() => {
                        this.rulesForm.type = '1'
                    })
            } else if (value == '1' && this.rulesForm.tourObject != '') {
                this.$confirm("提示", {
                    message: '切换巡课方式，已填写的内容将清除，且不可恢复，是否继续？',
                    confirmButtonText: "继续",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.timestamp = Date.now()
                        this.rulesForm.tourObject = ''
                        this.tourObjectLength = 0
                    })
                    .catch(() => {
                        this.rulesForm.type = '2'
                    })
            }
        },
        /**
         * @Description: 班级教师巡课范围切换
         * @Author: pyl
         * @Date: 2024-08-26 17:46:33
         */
        typeChange(value) {
            if (value == '2' && (this.checkedList2.length != 0 || this.rulesForm.classTargetId != '')) {
                this.$confirm("提示", {
                        message: '切换后，已选择的内容将清空，且不可恢复，是否继续？',
                        confirmButtonText: "继续",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.rulesForm.classTargetId = ''
                            this.rulesForm.tourObject = ''
                            this.rulesForm.targetType = value
                        })
                        .catch(() => {
                            console.log('取消')
                        })
            } else if (value == '1' && (this.checkedList3.length != 0 || this.rulesForm.teacherTargetId != '')) {
                this.$confirm("提示", {
                        message: '切换后，已选择的内容将清空，且不可恢复，是否继续？',
                        confirmButtonText: "继续",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.rulesForm.teacherTargetId = ''
                            this.rulesForm.tourObject = ''
                            this.rulesForm.targetType = value
                        })
                        .catch(() => {
                            console.log('取消')
                        })
            } else {
                this.rulesForm.targetType = value
            }
        },
        /**
         * @Description: 更改选择日期
         * @Author: pyl
         * @Date: 2024-08-29 15:06:20
         */
        dateChange(date) {
            if (this.rulesForm.id && (new Date(date[1]) < new Date(moment().format('YYYY-MM-DD')))) {
                this.rulesForm.time = [this.editData.startTime.split(' ')[0], this.editData.endTime.split(' ')[0]]
                return this.$message.warning('不应修改至当天之前的日期')
            }
            this.rulesForm.startTime = date[0] + ' 00:00:00'
            this.rulesForm.endTime = date[1] + ' 23:59:59'
        },
        /**
         * @Description: 更改推送类型
         * @Author: pyl
         * @Date: 2024-08-29 15:21:19
         */
        pushTypeChange(type) {
            this.rulesForm.isPermTeacher = type.includes('1') ? '1' : '0'
            this.rulesForm.isClassTeacher = type.includes('2') ? '1' : '0'
            this.rulesForm.isPost = type.includes('3') ? '1' : '0'
            this.rulesForm.isCustom = type.includes('4') ? '1' : '0'
        },
        postScopeChange(value) {
            this.rulesForm.postScope = []
            value.forEach(item => {
                const res = this.optionsList.find(i => i.id == item)
                this.rulesForm.postScope.push({
                    id: item,
                    name: res.name
                })
            })
        },
        /**
         * @Description: 获取职务列表
         * @Author: pyl
         * @Date: 2024-08-26 19:59:42
         */
        getJobList() {
            this._fet('/school/schoolDictValue/list', {
                dictKey: "teachingPost",
                schoolId: this.$store.state.schoolId,
                pageNum: 1,
                pageRow: 10000
            }).then((res) => {
                console.log('职务列表', res)
                this.optionsList = res.data.data.list.map(i => {
                return {
                    id: i.id,
                    name: i.name
                }
                })
            })
        },
        /**
         * @Description: 选中项切换
         * @Author: pyl
         * @Date: 2024-08-27 18:41:43
         * @param {*} activeItem
         */
        changeActive(activeItem) {
            if (activeItem == '方案设计') {
                this.$refs.formRef.validate((val) => {
                    if (val) {
                        this.activeItem = activeItem
                    }
                })
                if (this.rulesForm.assessType.includes('1') && this.rulesForm.assessType.includes('2')) {
                    this.groupType = ''
                } else if (this.rulesForm.assessType.includes('1')) {
                    this.groupType = '1'
                } else if (this.rulesForm.assessType.includes('2')) {
                    this.groupType = '2'
                }
                this.getGroupList()
            }
            this.activeItem = activeItem
        },
        /**
         * @Description: 获取左侧tab分组列表
         * @Author: mrz
         * @Date: 2024-08-25 16:36:20
         */
         getGroupList(){
            this._fet("/school/schoolPatrolGroup/getAllPatrolGroup", {groupType: this.groupType}).then(res => {
                this.handleRes(res, () => {
                    if (Object.keys(res.data.data).length != 0) {
                        res.data.data.forEach(item => {
                            this.$set(item, 'checked', false)
                        });
                    }
                    this.groupList = res.data.data
                })
            })
         },
         /**
         * @Description: 固定巡课对象点击清空按钮
         * @Author: mrz
         * @Date: 2024-08-25 16:36:20
         */
         delFixedPatrol(){
            this.$refs.childCourse.delSingleCourse()
            // this.tourObjectLength = 0
            // this.rulesForm.tourObject = ''
         },
        /**
         * @Description: 获取教师学生行为
         * @Author: pyl
         * @Date: 2024-08-28 10:33:34
         */
        getBehavior(id, groupType) {
            if (this.rulesForm.status == '2') {
                return this.$message.warning('已发布的方案不可修改行为')
            }
            if (groupType == '1' && !this.rulesForm.assessType.includes('1') || groupType == '2' && !this.rulesForm.assessType.includes('2')) {
                return this.$message.warning('请先在基础信息设置部分勾选对应的评价类型')
            }
            this._fet("/school/schoolPatrolContent/info/" + id, {}).then(res => {
                if(res.data.code == 200){
                    const index = this.teacherList.findIndex(i => i.id == res.data.data.id)
                    if (res.data.data.groupType == '1' && index == -1) {
                        this.teacherList.push(res.data.data)
                    } else if (index != -1) {
                        this.$message.warning('该巡课内容已添加，请勿重复添加')
                    }
                    const index2 = this.studentList.findIndex(i => i.id == res.data.data.id)
                    if (res.data.data.groupType == '2' && index2 == -1) {
                        this.studentList.push(res.data.data)
                    } else if (index2 != -1) {
                        this.$message.warning('该巡课内容已添加，请勿重复添加')
                    }
                    console.log('学生行为', this.studentList)
                }else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 巡课内容展开与折叠
         * @Author: pyl
         * @Date: 2024-08-28 14:18:50
         */
        fold(item) {
            item.checked = !item.checked
        },
        /**
         * @Description: 跳转到巡课内容页面
         * @Author: pyl
         * @Date: 2024-08-28 17:36:02
         */
        async jumpToContent() {
            if(isEqual(this.rulesForm, this.cloneRulesForm)){
                this.$router.push({
                    name: "SEduClassroomPatrolContent",
                    query: {}
                });
            } else {
                await this.toSave('1', true)
                if (this.jumpFlag) {
                    this.$router.push({
                        name: "SEduClassroomPatrolContent",
                        query: {}
                    });
                }
            }
            this.$router.push({
                name: "SEduClassroomPatrolContent",
                query: {}
            });
        },
        /**
         * @Description: 暂存，发布
         * @Author: pyl
         * @Date: 2024-08-30 13:03:58
         */
        async toSave(type, jump) {
            let rulesForm = this._.clone(this.rulesForm)
            if (type == '1') {
                await this.save(rulesForm, type, jump)
            } else {
                if (rulesForm.assessType.includes('1') && this.teacherList.length == 0) {
                    return this.$message.warning('教师行为未设置巡课内容，请添加巡课内容或取消该评价类型的勾选')
                }
                if (rulesForm.assessType.includes('2') && this.studentList.length == 0) {
                    return this.$message.warning('学生行为未设置巡课内容，请添加巡课内容或取消该评价类型的勾选')
                }
                if (rulesForm.pushType.includes('3') && rulesForm.postScope.length == 0) {
                    return this.$message.warning('请选择按职务推送')
                }
                if (rulesForm.pushType.includes('4') && rulesForm.customScope.length == 0) {
                    return this.$message.warning('请选择自定义推送人')
                }
                this.$refs.formRef.validate(async (val) => {
                    if (val) {
                        await this.save(rulesForm, type)
                    } else {
                        this.activeItem = '基础信息'
                    }
                })
            }
        },
        async save(rulesForm, type, jump) {
            rulesForm.status = type
            rulesForm.list = []
            this.teacherList.forEach((item, index) => {
                item.list.forEach((i, j) => {
                    rulesForm.list.push({
                        contentId: item.id,
                        contentName: item.content,
                        conSort: index + 1,
                        setId: i.id,
                        setName: i.name,
                        setSort: j + 1,
                        setScore: i.score,
                        groupType: i.groupType,
                        lowScore: i.lowScore + '',
                        highScore: i.highScore + '',
                        type: item.type
                    })
                })
                if (item.list.length == 0) {
                    rulesForm.list.push({
                        contentId: item.id,
                        contentName: item.content,
                        conSort: index + 1,
                        setId: '',
                        setName: '',
                        setSort: 1,
                        setScore: '',
                        groupType: item.groupType,
                        lowScore: '',
                        highScore: '',
                        type: item.type
                    })
                }
            })
            this.studentList.forEach((item, index) => {
                item.list.forEach((i, j) => {
                    rulesForm.list.push({
                        contentId: item.id,
                        contentName: item.content,
                        conSort: index + 1,
                        setId: i.id,
                        setName: i.name,
                        setSort: j + 1,
                        setScore: i.score,
                        groupType: i.groupType,
                        lowScore: i.lowScore + '',
                        highScore: i.highScore + '',
                        type: item.type
                    })
                })
                if (item.list.length == 0) {
                    rulesForm.list.push({
                        contentId: item.id,
                        contentName: item.content,
                        conSort: index + 1,
                        setId: '',
                        setName: '',
                        setSort: 1,
                        setScore: '',
                        groupType: item.groupType,
                        lowScore: '',
                        highScore: '',
                        type: item.type
                    })
                }
            })
            if (rulesForm.assessType.includes('3')) {
                rulesForm.list.push({
                    contentId: 'beizhushuoming',
                    contentName: '备注说明',
                    setId: 'beizhushuoming',
                    setName: '备注说明',
                    setScore: '',
                    groupType: '3',
                    lowScore: '',
                    highScore: '',
                    type: ''
                })
            }
            delete rulesForm.patrolTeacherId
            delete rulesForm.patrolTeacherName
            delete rulesForm.tourObject
            delete rulesForm.classTargetId
            delete rulesForm.teacherTargetId
            if (rulesForm.type == '2') {
                delete rulesForm.startTime
                delete rulesForm.endTime
            }
            delete rulesForm.time
            rulesForm.assessType = rulesForm.assessType.toString()
            delete rulesForm.pushType
            delete rulesForm.postScopeId
            delete rulesForm.customPushId
            delete rulesForm.customPushName
            if (rulesForm.isPost == '0') {
                delete rulesForm.postScope
            }
            if (rulesForm.isCustom == '0') {
                delete rulesForm.customScope
            }
            await this._fet("/school/schoolPatrolProgramme/save", rulesForm).then(res => {
                if(res.data.code == 200){
                    console.log('保存成功', res)
                    if (jump) {
                        this.$message.warning('已创建的方案将暂存')
                        setTimeout(() => {
                            this.$message.success('暂存成功')
                        }, 2000)
                    }
                    (type == '1' && !jump) && this.$message.success('暂存成功')
                    type == '2' && this.$message.success('发布成功')
                    this.$emit('switchCom', {com: 'List'})
                    this.jumpFlag = true
                } else if (res.data.msg){
                    this.jumpFlag = false
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.global-page-back {
    position: relative;
    top: -38px;
    z-index: 1;
    box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.1);
}
::v-deep .el-button.el-button--default span{
    display: flex;
}
.fixed-object{
    width: 360px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: flex;
    align-items: center;
    .tip {
        font-size: 13px;
        color: #C0C4CC;
        &:hover {
            color: #C0C4CC;
        }
    }
    .fixed-before{
        margin-left: -8px;
        display: flex;
        align-items: center;
        height: 25px;
        background: #F4F4F5;
        border-radius: 4px;
        border: 1px solid #E9E9EB;
        padding: 0 5px;
    }
    .fixed-info{
        display: inline-block;
        max-width: 265px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 25px;
    }
    .fixed-img{
        margin-left: 5px;
    }
    .fixed-num{
        margin-left: 5px;
        width: 40px;
        height: 25px;
        background: #F4F4F5;
        border-radius: 4px;
        border: 1px solid #E9E9EB;
        font-family: Microsoft YaHei;
        text-align: center;
        line-height: 25px;
        font-weight: 400;
        font-size: 14px;
        color: #909399;
    }
}
.other-content {
    width: calc(100vw - 342px);
    .wrap {
        display: flex;
        justify-content: center;
        div:first-child {
            margin-left: -75px;
        }
        div {
            margin-right: 63px;
            width: 79px;
            height: 48px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            align-items: center;
            cursor: pointer;
            span {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #6D7073;
                display: inline-block;
                width: 79px;

            }
            .active {
                color: #3C7FFF;
                font-weight: bold;
            }
            img {
                position: absolute;
                left: 45px;
                bottom: 0;
            }
        }
    }
}
.content-wrap {
    background-image: url("~@/assets/images/common/content-bg.png");
    background-size: cover;
    margin-top: -38px;
    .content{
        height: 100%;
        padding-top: 10px;
        .basic-information {
            width: 426px;
            height: calc(100vh - 187px);
            overflow-y: auto;
            margin: 0 auto;
            background: #fff;
            border-radius: 4px;
            padding: 56px 237px 0;
            .title {
                width: 64px;
                margin-bottom: 15px;
                .text {
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 16px;
                    color: #2B2F33;
                }
                .line {
                    width: 56px;
                    height: 3px;
                    background: #3C7FFF;
                    border-radius: 2px;
                    margin: 0 auto;
                    margin-top: 10px;
                }
            }
            .Parting-line {
                width: 448px;
                border-bottom: 1px dashed #DCDFE6;
                margin-bottom: 19px;
            }
        }
        .scheme-design {
            width: 1310px;
            display: flex;
            justify-content: center;
            margin: auto;
            .left-content{
                width: 227px;
                min-width: 285px;
                height: calc(100vh - 187px);
                overflow-y: auto;
                margin-right: 10px;
                background-color: #FFFFFF;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                .left-title{
                    box-sizing: border-box;
                    padding: 14px 0 12px 23px;
                    border-bottom: 1px solid #EDEFF2;
                    position: relative;
                    .line-bottom{
                        position: absolute;
                        left: 34px;
                        bottom: -1px;
                        width: 42px;
                        height: 3px;
                        background: #3C7FFF;
                        border-radius: 2px;
                    }
                    span{
                        display: flex;
                        justify-content: flex-start;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #2B2F33;
                        position: relative;
                    }
                }
                .left-tabs{
                    box-sizing: border-box;
                    padding: 12px 11px 0 11px;
                    ::v-deep .el-radio-button__inner{
                        width: 93px;
                        height: 36px;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                    }
                    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner{
                        background-color: #3C7FFF;
                        border-color: #3C7FFF;
                        color: #fff;
                    }
                }
                .left-plan {
                    .title {
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;
                        padding: 0 12px;
                        margin: 11px 11px 19px 11px;
                        background: #F5F7FA;
                        border-radius: 4px;
                        span {
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            font-size: 15px;
                            color: #333333;
                        }
                        img {
                            width: 12px;
                            height: 7px;
                        }
                    }
                    .detail {
                        cursor: pointer;
                        margin: 20px 24px;
                        width: 227px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .left-tips {
                    width: 277px;
                    height: 60px;
                    background: #FDF8D2;
                    border-radius: 0px 0px 4px 4px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    div {
                        width: 100%;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    .text {
                        margin-top: 5px;
                        color: #333333;
                    }
                    .create {
                        margin-bottom: 5px;
                        color: #3C7FFF;
                        cursor: pointer;
                    }
                }
            }
            .right-content{
                height: calc(100vh - 187px);
                overflow-y: auto;
                box-sizing: border-box;
                width: 70%;
                padding: 18px 22px;
                background-color: #fff;
                .behavior-line{
                    height: 0;
                    width: 100%;
                    margin-bottom: 10px;
                    border-bottom: 1px dashed #E1E3E6;
                }
                .behavior-content {
                    padding: 8px;
                    margin-top: 10px;
                    border-radius: 4px;
                    border: 1px solid #E1E3E6;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(100% - 16px);
                    cursor: move;
                    &:hover {
                        border: 1px dashed #3C7FFF;
                    }
                    img {
                        width: 17px;
                        height: 19px;
                        cursor: pointer;
                    }
                    .behavior-item {
                        display: block;
                        margin-right: 38px;
                        width: 350px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .behavior-item-checkbox{
                        display: flex;
                        margin-right: 38px;
                        align-items: center;
                    }
                }

                .right-teacher2 {
                    margin-bottom: 10px;
                }
                .right-student2 {
                    margin-bottom: 10px;
                }
                .right-title{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .title-line{
                        display: inline-block;
                        width: 4px;
                        height: 15px;
                        background: #3C7FFF;
                        border-radius: 1px;
                        margin-right: 7px;
                    }
                    .title-info{
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #2B2F33;
                    }
                }
                .right-none{
                    width: 240px;
                    margin: 30px auto;
                    text-align: center;
                    img {
                        width: 117px;
                        height: 83px;
                    }
                    .right-none-info{
                        margin-top: 10px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #999999;
                    }
                }
                .right-remark-main {
                    margin-top: 10px;
                }
            }
        }
    }
}
.behavior-content ::v-deep .el-checkbox__label{
    display: block;
    width: 325px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.botttom-btn {
    width: 100%;
    height: 54px;
    position: relative;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px -1px 3px 0px rgba(0,0,0,0.1);
}
::v-deep .el-radio-button__inner {
    width: 85px !important;
}
::v-deep .el-date-editor--daterange.el-input__inner {
    width: 335px;
}
.range-wrap {
    height: 400px;
    .btn-group {
        span {
            display: inline-block;
            padding: 3px 8px;
            border: 1px solid lightgray;
            cursor: pointer;
        }
        span:first-child {
            border-right: none;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        span:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
        .active {
            color: #FFFFFF;
            background-color: #3c7efe;
        }
    }
}
.type .el-checkbox {
    width: 100%;
}
.input-width {
    width: 360px;
}
.custom-input {
    width: 360px;
    height: 32px;
    position: relative;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 0 60px 0 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .person {
        max-width: 277px;
        padding: 3px 3px 3px 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        border-radius: 5px;
    }
    .nums {
        position: absolute;
        display: block;
        height: 22px;
        line-height: 22px;
        padding: 0 8px;
        top: 4px;
        right: 18px;
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
        font-size: 12px;
        border-radius: 4px;
    }
}
.disabled-input {
    cursor: not-allowed;
}
</style>
<style lang="scss">
.course-dialog .el-dialog .el-dialog__body {
    margin-top: 0 !important;
    padding: 20px 0px 20px 23px!important;
}
</style>
